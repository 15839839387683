.home-media-slider {
    .sn-media-slider .slider {
        // border: 2px solid $theme-color-gray;
        max-height: 336px;
    }
    // Caption
    .sn-media-slider .slides .slide .slide-overlay {
        padding: 5% 6% 4% 0;
        height: 50%;
        bottom: 0;
    }

    .sn-media-slider .slider .slide-text.top-left {
        background: rgba(0,0,0,.8);
        border-right: 6px solid $accent-color;
        padding: 20px 20px 10px 0;
        position: relative;

        &:before {
            @include custom-label;
            top: -22px;
        }

        .slide-description:before {
            content: "\003e\003e";
            margin-right: 10px;
            color: #900028;
        }
    }

    .sn-media-slider .slider .slide-title {
        font-size: em(22);
        letter-spacing: .1em;
        text-shadow: none;
        text-transform: uppercase;
        margin: 0 0 0 5%;
    }

    .sn-media-slider .slider .slide-description {
        //color: $hover-color;
        color: $button-highlight_text;
        font-family: $fontThree;
        font-weight: $font-light;
        font-size: em(18);
        letter-spacing: .1em;
        text-shadow: none;
        margin: 0 0 0 5%;
    }
    // Pager
    .sn-media-slider .slider-pagination .paging-item {
        height: 10px;
        width: 12%;
        background-color: $accent-color;
        border-right: 3px solid $theme-color-gray;
        margin-right: 20px;

        &.flex-active {
            background-color: $theme-color-gray;
        }
    }

    .sn-media-slider .slider.bottom-left-paging .slider-pagination,
    .sn-media-slider .slider.bottom-left-paging .slider-pagination-numbers {
        bottom: 5px;
    }
}
@media only screen and (max-width: 768px) {
    .home-media-slider .sn-media-slider .slider .slide-text.top-left:before {
        //top: -14px;
    }
}
