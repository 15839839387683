$scroller-image-width: 100;
$scroller-image-padding: 15;
$scroller-images-per-row: 7;
$scroller-container-width: (($scroller-image-width + $scroller-image-padding) * $scroller-images-per-row);
$scroller-container-width-cell: (($scroller-image-width + $scroller-image-padding) * 3);
$scroller-container-width-tablet: (($scroller-image-width + $scroller-image-padding) * 6);

body:not(.home) {
    .sponsorScroller-header,
    .sponsorScroller.xsponsorBar {
        display: none;
    }
}

.user_mode .sponsorScroller .column-1 {
    width: 100%;
    //max-width: $scroller-container-width + px;
    max-width: $scroller-container-width-cell + px;
    height: 135px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: 0;
    margin: 0 auto;
    float: none;
}

#sponsorScrollerGroup {
    position: relative;
    left: 0;
    padding: 0;
    margin: 17px 65px;
}

.user_mode .layoutContainer.layout-100.sponsorScroller {
    background: #fff;
    margin: 0;
    position: relative;
    padding: 0;
    //border: 2px solid $theme-color-gray;
    .layoutContainerInner {
        //display: table;
    }
}

.sponsorScroller .column-1 {
    text-align: center;
}

.user_mode .layoutContainer.sponsorScroller .pageEl {
    display: inline-block;
    width: $scroller-image-width + $scroller-image-padding + px;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
}

.user_mode .layoutContainer.sponsorScroller .pageEl .heroPhotoElement {
    overflow: hidden;
    margin: 0;
}

.user_mode .layoutContainer.sponsorScroller .pageEl .heroPhotoElement img {
    height: auto;
    max-height: 100px;
    max-width: 70%;
    width: auto;
    border: 0;
    padding: 0;
}

#sponsorScrollerNext,
#sponsorScrollerPrev {
    color: rgba(255, 255, 255, 0);
    width: 65px;
    line-height: 100px;
    height: 100px;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: background-color .5s ease;
}

#sponsorScrollerNext {
    left: auto;
    right: 0;
    background: #fff url('../images/arrowRight.svg') center no-repeat;
    background-size: 20px;
}

#sponsorScrollerPrev {
    background: #fff url('../images/arrowLeft.svg') center no-repeat;
    background-size: 20px;
}
/* Scales down logos in edit mode so they're easier to manage */
.edit_mode .layoutContainer.sponsorScroller div.drag {
    display: inline-block;
    width: 33%;
    float: left;

    .heroPhotoElement img {
        max-height: 100px;
        width: auto;
        border: 0;
        max-width: 100px;
    }
}
//

.sponsorBar {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    background: transparent;
    box-shadow: none;
}

.sponsorBar .sponsorMessage {
    border-bottom: 1px solid #efefef;
    display: block;
    margin: auto;
    padding: 20px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

body#home.edit_mode .sponsorBar {
    display: block;
}

body#home.edit_mode .sponsorBar .pageEl {
    float: none;
}

.sponsorBar a.sliderBtn {
    background: none repeat scroll 0 0 #e13b33;
    border-right: medium none;
    color: #fff;
    display: inline;
    float: left;
    font-size: 1.5em;
    padding: 60px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 5.5%;
    z-index: 9;
}

.sponsorBar a.sliderBtn:hover {
    opacity: .7;
}

.sponsorBar a.sliderBtn#nextBtn {}

.sponsorBar a.sliderBtn#prevBtn {}

.sponsorInner {
    background-color: #fff;
    display: block;
    float: left;
    max-width: 89%;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 133px;
}

.sponsorBar .pageEl {
    border-color: #cccccc #ffffff #cccccc #cccccc;
    border-style: solid;
    border-width: 0;
    display: block;
    float: left;
    width: auto;
    height: auto;
    margin-top: 0;
    width: 225px;
}

.sponsorBar .pageEl:last-child {}

.sponsorBar .heroPhotoElement {
    text-align: center;
}

.sponsorBar .heroPhotoElement a {
    width: 100%;
}

.sponsorBar .heroPhotoElement a img {
    width: auto;
    max-height: 125px;
    max-width: 175px;
    margin: auto;
    display: inline;
    vertical-align: middle;
}

.sponsorBar .pageEl .heroPhotoElement a:hover {
    opacity: .7;
}

.sponsorBar .pageEl .heroPhotoElement a:active {
    opacity: .7;
}

.sponsorBar .column {
    width: auto;
    position: relative;
    /*-webkit-transition: left 1s;
    transition: left 1s;*/
}

.sponsorBar a#nextBtn:active {
    color: #ffffff;
}

.sponsorBar a#prevBtn:active {
    color: #ffffff;
}
/* CELL PHONES (320px) */
@media only screen and (min-width: 20em) {}
/* TABLETS (768px) */
@media only screen and (min-width: 48em) {
    .user_mode .sponsorScroller .column-1 {
        max-width: $scroller-container-width-tablet + px;
    }
}
/* DESKTOPS (1024px) */
@media only screen and (min-width: 64em) {
    .user_mode .sponsorScroller .column-1 {
        max-width: $scroller-container-width + px;
    }

    .layoutContainer.layout-100.sponsorScroller.xsponsorBar {
        margin: 0 3%;
    }
}
