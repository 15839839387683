#poweredByNGIN {
    position: absolute;
    right: 0;
}

#siteContainer {
    #siteContainer.container-fluid,
    & {
        padding: 15px 0;
        width: 100%;
        max-width: $maxWidth;
        min-height: 100%;
        min-height: 100vh;
        min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
        margin: 0 auto;
        background: #fff;
    }
    @media only screen and (max-width: 1024px) {
        max-width: 1024px;
    }

    #displayBodyHeader:not(.displayBodyHeader-image) {
        padding: 0;
        font-size: em(32);
        font-weight: $font-weight-xbold;
        /* border-bottom: $borderWidth solid $borderColor; */
        text-transform: initial;
        line-height: em(24);
        letter-spacing: .010em;
        //border-left: 5px solid $accent-color;
        //padding-left: 29px;
        color:$accent-color;
        margin: 10px 0 20px 40px;
        text-transform: uppercase;
    }

    #yieldContent.row {
        margin: auto;
    }

    #panelOne {
        padding: 0;
    }

    .yieldPageContent {
        padding: 0;
    }
}
