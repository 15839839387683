$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;

#sn-site-logo {
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -609px;
    //margin-left: 0 - $maxWidth/2;
    width: 294px;
    height: 72px;
    //width: $site-logo-size;
    //height: $site-logo-size;
    padding: 0;
    background-color: none;
    border: none;
    z-index: 1000;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: all $transition-linear;

    .nav-fixed & {
        //top: -4px;
        //width: 294px;
        //width: $site-logo-size-fixed;
        //height: $site-logo-size-fixed;
    }

    .sn-site-logo-wrap {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;
    }

    .sn-site-logo-background {
        display: table-cell;
        vertical-align: middle;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        -webkit-background-size: contain;
    }
}
@media (max-width:1240px) {
  #sn-site-logo {
    height: 50px;
    top: 30px;
    width: 200px;
    margin-left: -480px;
  }  
}
