.customCTA .sn-call-to-action {
    outline: 2px solid white;
    padding: 0;
    &:after,
    &:before {
        display: none;
    }
    .sn-call-to-action-overlay {
        padding: 0;
    }
    img {
        opacity: 0.75;
        transition: opacity 0.5s;
        width: 100%;
        height: 100%;
    }
    &:hover img {
        opacity: 1;
        width: 100%;
        height: 100%;
    }
    .sn-call-to-action-overlay-text {
        display: block;
        background: rgba(0,0,0,.75);
        bottom: 0;
        width: 100%;
        height: 37%;
        position: absolute;
    }
    .sn-call-to-action-title {
        font-family: $fontOne;
        font-weight: $font-sbold;
        font-size: em(18);
        line-height: em(18);
        letter-spacing: 0.1em;
        padding: 15px 15px 0;
        margin-bottom: 0;
    }
    .sn-call-to-action-subtitle {
        padding: 10px 15px 0;
        & span {
            font-family: $fontThree;
            font-weight: $font-light;
            font-size: em(14);
            line-height: em(14);
            letter-spacing: 0.1em;
            //color: $hover-color;
            color:$button-highlight_text;
        }
    }
}
