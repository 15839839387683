.user_mode {
    #topNav .theme-main-nav ul.theme-nav li:first-child {
        display: none;
    }

    .partnerLink {
        position: absolute;
        top: 5px;
        right: 0;
        &.mobile-partner{
          top: auto;
          right: auto;
          position: relative;
          width: 100%;
          text-align: center;
        }
        width: 175px;
        padding: 0;
        margin: 0;
        text-align: right;
    }

    .partnerLink .linkElement h4 a {
        font-size: em(12);
        background: none;
        box-shadow: none;
        display: inline;
        color: $button-highlight_text;
        padding: 0 10px;
        margin: 0;
        text-decoration: none;
        font-weight: normal;
        border-right: 1px solid $custom-link-border;
        border-left: 1px solid $custom-link-border;
        cursor: pointer;
        z-index: 1;

        &:after {
            display: none;
        }
    }
}

.user_mode {
    .globalFooterSocial {
        background-color: $accent-color;
        border-bottom: 5px solid $theme-color-black;

        .column-1 {
            float: none;
            max-width: 1000px;
            margin: 0 auto;
            text-align: center;
        }

        &.layoutContainer .pageElement {
            margin: 0;
        }

        .linkElement h4 a {
            color: $button-highlight_text;
            font-weight: $font-xbold;
            font-size: em(24);
            background: none;
            box-shadow: none;
            line-height: normal;
            letter-spacing: .2em;
            padding: 15px 0;
        }

        .linkElement .emailLink:after,
        .linkElement .goToLink:after {
            display: none;
        }
    }
}

.user_mode {
    .globalFooterLinks {
        background-color: $theme-color-black;
        border-top: 5px solid $button-highlight_text;
        color: $theme-color-md-gray;
        display: flex;
        justify-content: center;
        padding-top: 20px;

        .footerAddress {
            //width: 300px;
            width: 100%;
            background: url("https://cdn3.sportngin.com/attachments/logo_graphic/6753/9502/Ice_Den_Scottsdale.svg") left top no-repeat;
            background-size: 187px auto !important;
            padding-top: 60px;
            #ngin12906 & {
              background: url("https://cdn3.sportngin.com/attachments/logo_graphic/6816/1034/Ice_Den_Chandler.svg") left top no-repeat;
            }

            .textBlockElement {
                * {
                    font-size: em(12);
                    line-height: em(25);
                    font-weight: $font-normal;
                }
            }
        }


        .footerLinks {
            //width: 150px;
            width: 100%;
            padding-top: 25px;

            .textBlockElement {
                h3 {
                    color: $button-highlight_text;
                    font-family: $fontThree;
                    font-weight: $font-bold;
                    font-size: em(18);
                    line-height: em(24);
                    letter-spacing: .1em;
                    border: none;
                    padding: 0;
                }
                .text {
                  padding: 0 5px;
                }
            }
        }

        .footerMap {
            //width: 230px;
            width: 100%;
            padding: 0 20px;
        }

        .column-1,
        .column-3 {
            display: none;
        }

        .column-2 {
            border-bottom: 1px solid $theme-color-dk-gray;
            width: 100%;
            max-width: 1000px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .sponsorScrollerTitle {
        padding-top: 35px;
        background: #fff;

        .textBlockElement h3 {
            text-align: center !important;
            color: $accent-color;
            font-size: em(24);
            font-weight: $font-xbold;
            letter-spacing: .2em;
            border: none;
            padding: 0;

            span {
                border-bottom: 1px solid $hover-color;
            }
        }
    }
}

#topNav .theme-mobile-nav:before {
    content: $site-title;
    display: block;
    width: 60%;
    height: 35px;
    position: absolute;
    color: #fff;
    top: 10px;
    left: calc(50% - 30%);
    text-align: center;
}
@media only screen and (max-width: 768px) {
    #siteFooter {
        padding: 0;
        box-sizing: border-box;
    }

    #siteFooter > ul {
        padding: 0 20px;
    }

    #siteFooter > ul li {
        padding: .5em .2em;
    }

    #siteFooter li {
        width: 100%;
        display: block;
        text-align: center;
        padding: 15px 0;
    }

    #siteFooter a#loginLink {
        display: block;
    }

    #siteFooter li#poweredByNGIN a {
        display: block;
        width: 50%;
        height: 20px;
        position: relative;
        margin: auto;
    }

    #siteFooter li#poweredByNGIN a:after {
        position: relative;
        margin-top: 25px;
    }

    .user_mode .globalFooterLinks .footerAddress {
        background-position: center top;
        text-align: center;
    }

    .user_mode .globalFooterLinks .footerLinks {
        display: none;
    }
}
@media (min-width: 768px) {
    .user_mode {
        .sponsorScrollerTitle .textBlockElement h3 {
            font-size: em(32);
        }

        .globalFooterLinks {
            .column-2 {
                flex-direction: row;
            }

            .footerAddress {
                width: 300px;
            }

            .footerLinks {
                width: 150px;
            }

            .footerMap {
                width: 230px;
            }
        }
    }
}
@media (min-width: 992px) {
    #topNav {
        border-bottom: 2px solid #fff;
    }

    ul#accountNav:before {
        display: none;
    }
}
