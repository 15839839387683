.eventAggregatorElement {
    color: $theme-color-md-gray;

    a {
        color: $theme-color-md-gray;
    }

    .summary {
        font-family: $fontThree;
        font-weight: $font-bold;
        color: $theme-color-black;

        a {
            color: $theme-color-black;
            letter-spacing: .1em;
        }
    }

    .vevent {
        padding: 30px 0 0;
    }

    .details {
        font-size: 12px;
    }

    .location,
    .location > a {
        color: $hover-color;
    }

    .tags,
    .tags a {
        color: $theme-color-gray;
    }

    .vevent:first-of-type {
        padding-top: 50px;
    }

    .dateImage {
        background: $theme-color-black;
        border-right: 5px solid $accent-color;
        letter-spacing: .2em;
        width: 60px;
        margin-left: 20px;

        &:after {
            display: none;
        }

        .date {
            font-family: $fontThree;
            font-size: em(28);
            color: white;
        }

        .month {
            color: $button-highlight_text;
            font-weight: $font-light;
            letter-spacing: .2em;
        }
    }
}

.home .eventAggregatorElement {
    .summary {
        color: white;

        a {
            color: white;
        }
    }
}

.eventPage {
    .eventTags {
        padding-top: em(15);
    }
}
