@mixin custom-label {
    content: $site-name;
    display: block;
    background: $hover-color;
    width: 145px;
    height: 22px;
    font-size: em(12);
    line-height: 22px;
    color: $custom-label-text-color;
    text-align: right;
    padding-right: 10px;
    box-sizing: border-box;
    position: absolute;
    letter-spacing: .25em;
    z-index: 1;
}
@mixin home-element-bg {
    background: rgba(0,0,0,.5);
}

body.edit_mode.home #siteContainer {
    padding-top: 50px;
}

.home:not(.newsPage) {
    #topNav {
        //height: 105px;
    }

    .theme-nav-item {
        padding: 15px 7px;
    }

    .theme-nav-dropdown .theme-nav-item {
        padding: 0 7px;
    }

    &.user_mode .theme-sub-nav {
        display: none !important;
    }

    &.user_mode #siteContainer {
        max-width: none;
        background: none;
        padding: 15px 0 0;
    }

    &.edit_mode #siteContainer {
        background: none;
    }

    #topNavPlaceholder {
        border-bottom: none;
        height: 90px;
    }

    #panelTwo {
        padding: 0;
    }

    &.user_mode .full-width .layoutContainerInner {
        max-width: 1000px;
        margin: auto;
    }

    &.user_mode .hero.full-width {
        min-height: 400px;
        border-bottom: 2px solid #fff;
        background: url("../images/hero-background-1.jpg") center 20px fixed;
        background-repeat: no-repeat;
        position: relative;
        background-position: center center;
        // background-size: cover;
        background-size: 2000px 500px;

        .column-1 {
            max-width: 1000px;
        }

        &:after {
            content: '';
            position: absolute;
            width: 45px;
            height: 45px;
            display: block;
            left: calc(50% - 22px);
            bottom: 25px;
            margin: auto;
            background: url("../images/arrowDown.svg") center bottom no-repeat;
            background-size: 45px;
            opacity: .7;
        }
    }

    &.user_mode.logged_in .hero.full-width {
        background-position: center 42px;
    }

    &.user_mode .ctas.full-width {
        padding: 35px 0;
        background-color: $theme-color-lt-gray;
        background-repeat: no-repeat;
        background-position: center top;
    }

    &.user_mode .news.full-width {
        padding: 35px 0;
        background-color: $theme-color-dk-gray;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: auto 100%;
    }

    &.user_mode .mediaLinks.full-width {
        padding: 35px 0;
    }

    .hero .textBlockElement .text p span {
        font-size: em(20);
        font-weight: 300;
        font-family: $fontThree;
        //color: $hover-color;
        color: $button-highlight_text;
        border-bottom: 1px solid $accent-color;
        padding-bottom: 7px;
        letter-spacing: .1em;
        line-height: 48px;
        letter-spacing: .1em;
    }

    .hero .textBlockElement .text h1 {
        font-size: em(42);
        font-family: $fontOne;
        font-weight: 900;
        color: #fff;
        letter-spacing: .05em;
    }

    .hero .text {
        padding-top: 10%;
    }

    &.user_mode .hero .bgImage .heroPhotoElement {
        display: none;
    }

    .aggHeader {
        font-size: em(32);
        line-height: em(26);
        padding-left: 0;
        background: none;
        margin-bottom: 20px;
        color: white;
        border: none;

        span {
            @include special-header-span;
            border-left: 5px solid $accent-color;
            font-size: em(32);
            line-height: em(26);
            padding-left: 10px;
        }

        &:after {
            @include custom-label;
            top: 47px;
        }
    }

    .newsAggregatorElement .item {
        @include home-element-bg;
        border: 0;
        border-right: 5px solid $hover-color !important;
        padding: 15px 15px 15px 30px;
    }

    .newsAggregatorElement .item ul.details {
        padding-bottom: 30px;
    }

    .newsAggregatorElement .item:after {
        content: '';
        display: block;
        width: 94.5%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        position: absolute;
        right: 20px;
    }

    .newsAggregatorElement .item:first-of-type {
        padding-top: 50px;
    }

    .newsAggregatorElement .item a {
        height: 70px;
        position: relative;
    }

    .newsAggregatorElement .item a img {
        width: 127px;
        position: absolute;
        top: 0;
        left: 0;
        clip: rect(0,127px,72px,0);
    }

    .newsAggregatorElement .item > a:first-child {
        padding: 0 0 1px;
        margin-right: 30px;
        margin-bottom: 20px;
        width: 127px;
        height: 72px;

        &:before {
            content: '';
            border: 1px solid #fff;
            display: block;
            width: 127px;
            height: 72px;
            position: absolute;
            top: -1px;
            left: -1px;
        }

        &:after {
            content: '';
            border-right: 5px solid $accent-color;
            display: block;
            width: 127px;
            height: 75px;
            position: absolute;
            top: -1px;
            right: -6px;
        }
    }

    .newsAggregatorElement .item .readMore a {
        background: none;
        border: 0;
    }

    .newsAggregatorElement .condensed .dateAuthor {
        .newsAuthor,
        .newsDate {
            color: $theme-color-md-gray;
        }
    }

    .newsAggregatorElement .condensed .teaser {
        font-size: em(12);
        margin: .2em 0;
        color: $hover-color;
    }

    .newsAggregatorElement .readMore {
        display: none;
    }

    .newsAggregatorElement .extendedOptions a,
    .newsAggregatorElement .newsAggregatorElement .pageOptions a {
        color: $theme-color-md-gray;
    }

    .newsAggregatorElement .item.extendedOptions:after {
        display: none;
    }

    .eventAggregatorElement .vevent,
    .eventAggregatorElement .vevent.even,
    .eventAggregatorElement .vevent.odd {
        @include home-element-bg;
        border: 0;
        border-right: 5px solid $hover-color !important;
        padding-right: 15px;
    }

    .eventAggregatorElement .vevent:after {
        content: '';
        display: block;
        width: 98%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        position: absolute;
        right: 6px;
        padding-top: 10px;
    }

    .eventAggregatorElement .extendedOptions {
        @include home-element-bg;
        padding: 25px 0;
        border: none !important;
        border-right: 5px solid $hover-color !important;
        padding-right: 15px;
    }

    .aggregatorElement h4 a {
        font-family: $fontThree;
        font-size: em(16);
        letter-spacing: .05em;
        color: #fff;
    }

    .mediaLinks .textBlockElement h3 {
        @include special-header;
        background: none;
        padding-left: 0;
        border: none;
        color: white;
    }

    .mediaLinks .textBlockElement h3:after {
        @include custom-label;
        top: 32px;
    }

    .mediaLinks .textBlockElement h3 span {
        @include special-header-span;
        border-left: 4px solid $hover-color;
        padding-left: 10px;
    }

    .mediaLinks div.text {
        border: 0;
        border-right: 5px solid $hover-color !important;
    }

    .mediaLinks .textBlockElement div.text {
        @include home-element-bg;
        padding: 0;
        color: #fff;
    }

    .mediaLinks .textBlockElement div.text a {
        //color: $hover-color;
        color: $button-highlight_text;
        font-size: em(16);
        font-weight: 400;
        letter-spacing: .05em;
        transition: padding .5s;
    }

    .mediaLinks .textBlockElement div.text a:hover {
        color: #fff;
        text-decoration: none;
        padding-left: 5px;
    }

    .mediaLinks .textBlockElement div.text p:before {
        content: "\003e\003e";
        margin-right: 5px;
        color: $accent-color;
    }

    .mediaLinks .textBlockElement div.text p {
        color: $hover-color;
        padding: 7px 15px;
    }

    .mediaLinks .textBlockElement div.text p:first-child {
        padding-top: 50px;
    }

    .mediaLinks .textBlockElement div.text p:last-child {
        padding-bottom: 30px;
    }
}

.eventPage {}
@media only screen and (min-width: 768px) {
    .home:not(.newsPage) .hero .textBlockElement .text h1 {
        font-size: em(48);
    }
}
// @media (min-width: 768px) {
//     .home .site-background {
//         background-position: center 40px;
//     }
// }
// @media (min-width: 992px) {
//     .home .site-background {
//         background-position: center 40px;
//     }
//     .home.user_mode .full-width {
//         flex-direction: row;
//     }
//     .home.user_mode .full-width .column {
//         flex-flow: nowrap;
//     }
// }
// @media (min-width: 1200px) {
//     .home .site-background {
//         background-position: center 20px;
//     }
//     .home.logged_in .site-background {
//         background-position: center 60px;
//     }
// }
