/* RESPONSIVE */
/* maxWidth Desktop Screen */

@media only screen and (max-width: 1024px) {
  /* > 1023px Styles */

  /* Content Tabs */
  .tabbedElement {
    ul.contentTabs {
      li {
        width: 33%;
        padding: 2px;

        span {
          a {
            width: 100%;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }

  /* Game Show */
  #liveGameSimple{
    display: none;
  }

  /* Footer */
  #siteFooter{
	  li{
  	  min-height:0;
    }
	}
  #siteFooter li#poweredByNGIN{
    padding:30px 0 0 0;
    width:100%;
  }
}
/* Tablets & Phones */
@media only screen and (max-width: 767px) {

  /* > 769px Styles */

  /* Admin */
  .adminToggle,
  #adminNav {
    display: none;
  }
  #siteContainer {
    margin-top: 0;
    padding-left: 0;

    #panelTwo {
      .layoutContainer .column {
        width: 100%;
        display: block;
      }
    }
  }
  /* Code Element */
  .codeElement{
    object, embed, iframe { width: 100%; }
  }

  /* Content Tabs */
  .tabbedElement {
    ul.contentTabs li {
      width: 50%;
      padding: 2px;

      span a {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }

  /* Widget Elements */
  .widgetElement {
    .widget-roster-player {
      table.statTable {
        thead {
          display: block;
          float: left;
          position: relative;
          width: 50%;

          tr {
            float: left;
            position: relative;
            display: block;
            width: 100%;

            th {
              display: block;
              float: left;
              height: 30px;
              overflow: hidden;
              padding-top: 8px;
              width: 100%;
            }
          }
        }

        tbody {
          display: block;
          float: left;
          position: relative;
          width: 50%;

          tr {
            float: left;
            position: relative;
            display: block;
            width: 100%;

            td {
              display: inline-block;
              float: left;
              height: 30px;
              width: 100%;
              border-left: none;
              border-right: none;
            }
          }
        }
      }
    }

    .widget-gameTable .showMoreGames { display: none; }
  }

  /* Survey Reports */
  #survey_steps {
    .siteContainer {
      #accountNav { margin: auto; padding: 0; }
      .adminToggle { top: 0; }

      #pageContentContainer {
        #panelOne {
          width: 100%;
          max-width: 100%;
          display: block;
          float: none;
          padding: 0;
          padding-bottom: 30px;
        }

        #panelTwo {
          width: 100%;
          max-width: 100%;
          display: block;
          float: none;
          padding: 0;
        }
      }
    }
  }
/* TEAM PAGES */
/* Game Show Content */
  #GameShowContent li.tab_menu-tab{
    width: 50%;
  }
  .teamTagFilter label{
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 640px) {

  /* > 640px Styles */

  #siteFooter{
	  li{
		  min-height:0;
	  }
	}

  	#siteFooter li#poweredByNGIN{
  		padding:10px 0;
	}
}
@media only screen and (max-width: 480px) {
  /* > 480px Styles */

   /* Stats */
  .StatWidgetContainer{padding:15px 0 0 0;}
  .StatWidget{margin:0 0 20px 0;}
  .StatWidgetContainer.game_details{padding:20px 10px;}
  .GameShowDataContainer .StatWidgetGroup{margin:0 10px 20px 0;}
  #GameShowContent ul.player_stats-list-header li h2{font-size:0.75em}
  #GameShowContent .GameShowDataContainer.game_preview ul.team_stats-list {font-size:0.8em;}
  .player_stat-leader .player_name{font-size:1em;}
  .teamLogo-medium, .teamLogor-medium{width:70px !important; height:23px !important;}

  /* filter styles */
  .tag-filterForm .tag-filterWrap{
	  width:100%;
	  padding:16px 0;
  }
  .tag-filterForm .tag-filterWrap.button{
	  transform:translate(0,0);
	  -webkit-transform:translate(0,0);
  }
  .tag-filterForm .inputChoice{
	  float:right;
  }
  .tag-filterWrap.dropdown label{
	  width:100%;
	  select{
		  float:right;
		  width:70%;
	  }
  }
}
